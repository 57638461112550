<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <smart-view-dialogue-base
        :close="
            () => {
                this.$emit('close-dialog');
            }
        "
    >
        <template v-slot:heading>
            All Reports on {{ datasetGroupName }}
        </template>
        <template v-slot:content>
            <v-container v-if="errors[datasetGroupUuid]">
                <v-alert
                    v-if="errors[datasetGroupUuid].type === 'error'"
                    type="error"
                >
                    Error updating list of reports
                </v-alert>
                <v-alert
                    v-if="errors[datasetGroupUuid].type === 'FAILURE'"
                    type="error"
                >
                    Creating Report Failed Please Contact Support
                </v-alert>
                <v-alert
                    v-if="errors[datasetGroupUuid].type === 'forbidden'"
                    type="warning"
                >
                    You do not have access to reports for this dataset
                </v-alert>
                <v-alert
                    v-if="errors[datasetGroupUuid].type === 'SUCCESS'"
                    :color="view_options[view.component].color"
                    style="color: white;"
                >
                    <template v-slot:prepend>
                        <v-icon
                            size="30"
                            color="white"
                            v-text="'$vuetify.icons.info'"
                        />
                    </template>
                    <v-row align="center" no-gutters>
                        <v-col class="grow">
                            Report has finished processing
                        </v-col>
                        <v-col class="shrink">
                            <v-btn :href="errors[datasetGroupUuid].url">
                                Download
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-alert>
                <report-alert
                    v-if="errors[datasetGroupUuid].type === 'PENDING'"
                    message="Waiting in Queue"
                    :color="view_options[view.component].color"
                />
                <report-alert
                    v-if="errors[datasetGroupUuid].type === 'STARTED'"
                    message="Getting Annotation Information"
                    :color="view_options[view.component].color"
                />
                <report-alert
                    v-if="errors[datasetGroupUuid].type === 'IMAGE'"
                    message="Generating Images"
                    :current="errors[datasetGroupUuid].image"
                    :total="errors[datasetGroupUuid].total"
                    :color="view_options[view.component].color"
                />
                <report-alert
                    v-if="errors[datasetGroupUuid].type === 'GENERATING'"
                    :color="view_options[view.component].color"
                    message="Generating PDF"
                />
                <report-alert
                    v-if="errors[datasetGroupUuid].type === 'UPLOADING'"
                    :color="view_options[view.component].color"
                    message="Uploading PDF"
                />
            </v-container>

            <v-row style="margin: 0" class="px-10 py-2">
                <v-btn
                    :color="view_options[view.component].color"
                    class="mt-3"
                    style="color: white"
                    @click="generateReport()"
                >
                    Generate {{ view_options[view.component].text }} Report
                </v-btn>
                <div class="flex-grow-1" />
                <v-text-field
                    v-model="search"
                    append-icon="$vuetify.icons.search"
                    label="Search"
                    single-line
                    hide-details
                />
            </v-row>

            <v-container>
                <v-data-table
                    :headers="headers"
                    :items="reports"
                    :hide-default-footer="reports.length < 5"
                    :items-per-page="5"
                    :search="search"
                    :loading="loading"
                    :sort-desc="true"
                    sort-by="date"
                    loading-text="Loading reports..."
                    no-data-text="There are no reports for this asset, generate one now"
                >
                    <template v-slot:item.date="{ item }">
                        {{ item.date.split(',')[0] }} <br />
                        {{ item.date.split(',')[1] }}
                    </template>
                    <template v-slot:item.assetDate="{ item }">
                        {{ item.assetDate.split(',')[0] }} <br />
                        {{ item.assetDate.split(',')[1] }}
                    </template>
                    <template v-slot:item.viewType="{ item }">
                        <v-chip
                            :color="view_options[item.viewType].color"
                            text-color="white"
                        >
                            <v-avatar left>
                                <v-icon
                                    small
                                    v-text="view_options[item.viewType].icon"
                                />
                            </v-avatar>
                            {{ view_options[item.viewType].text }}
                        </v-chip>
                    </template>
                    <template v-slot:item.url="{ item }">
                        <template
                            v-if="
                                item.state === 'STARTED' ||
                                    item.state === 'PENDING' ||
                                    item.state === 'GENERATING' ||
                                    item.state === 'UPLOADING'
                            "
                        >
                            <v-progress-circular
                                :color="view_options[item.viewType].color"
                                size="23"
                                class="ml-4 my-4"
                                indeterminate
                            />
                        </template>
                        <template v-if="item.state === 'FAILURE'">
                            <v-icon
                                color="red"
                                size="28"
                                class="ml-4 my-3"
                                v-text="'$vuetify.icons.close'"
                            />
                        </template>
                        <template v-if="item.state === 'SUCCESS'">
                            <v-btn :href="item.url" icon>
                                <v-icon
                                    small
                                    v-text="'$vuetify.icons.download'"
                                />
                            </v-btn>
                        </template>
                    </template>
                </v-data-table>
            </v-container>
        </template>
    </smart-view-dialogue-base>
</template>

<script>
import axios from 'axios';
import ReportAlert from 'components/Reports/ReportAlert.vue';
import SmartViewDialogueBase from 'components/View/SmartViewDialogueBase.vue';
import { trackReportAndExportEvents } from 'js/eventsTracking';
import viewsInfoMixin from 'js/mixins/viewsInfoMixin';
import { SmartViewEventBus } from 'store/store';
import { mapMutations, mapState } from 'vuex';

export default {
    name: 'ReportsVue',
    components: {
        SmartViewDialogueBase,
        ReportAlert,
    },
    mixins: [viewsInfoMixin],
    props: {
        view: {
            type: Object,
            default: () => {
                return {
                    datasetPackage: {
                        dataset_name: 'Dataset Name',
                        dataset_group_uuid:
                            '11111111-1111-1111-1111-1111111111',
                    },
                };
            },
        },
    },
    data() {
        return {
            search: '',
            headers: [
                { text: 'User', value: 'user_name', align: 'left' },
                { text: 'Report Date', value: 'date' },
                { text: 'Asset Date', value: 'assetDate' },
                { text: 'View Type', value: 'viewType' },
                { text: 'Download', value: 'url' },
            ],
            loading: true,
            pollInterval: null,
            pollCount: 0,
            errorCount: 0,
            site_uuid: null,
        };
    },
    computed: {
        ...mapState('reports', ['datasetGroups', 'errors']),
        ...mapState('smartview', ['getIsReportMode']),
        reports() {
            // sometimes it was returning undefined before the ajax request had completed, halting js execution
            return typeof this.datasetGroups[this.datasetGroupUuid] ===
                'undefined'
                ? []
                : this.datasetGroups[this.datasetGroupUuid];
        },
        datasetGroupName() {
            return this.view.datasetPackage.name;
        },
        datasetGroupUuid() {
            return this.view.datasetPackage.dataset_group_uuid;
        },
    },
    watch: {
        datasetGroupUuid: {
            immediate: true,
            handler() {
                this.getReports();
            },
        },
    },
    mounted() {
        if (!this.datasetGroups[this.dataestGroupUuid]) {
            this.addReports([this.datasetGroupUuid, []]);
        }
        this.pollInterval = setInterval(() => {
            this.getReports();
        }, 5000);
        // update every 5 seconds

        // get and store site_uuid
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        if(urlParams.has('site')){
            this.site_uuid = urlParams.get('site');
        }
    },
    beforeDestroy() {
        clearInterval(this.pollInterval);
        this.addErrors([this.datasetGroupUuid, null]);
    },
    methods: {
        ...mapMutations('reports', ['addReports', 'addErrors']),
        ...mapMutations('smartview', ['setIsReportMode']),
        getReports() {
            axios
                .get(`api/database/get_reports/${this.datasetGroupUuid}`)
                .then(response => {
                    // check for changes
                    for (let report in response.data) {
                        if (
                            !(report in this.reports) ||
                            this.reports[report].state !==
                                response.data[report].state
                        ) {
                            if (response.data[report].state === 'SUCCESS') {
                                // if report finished processing notify the user
                                this.addErrors([
                                    this.datasetGroupUuid,
                                    {
                                        type: response.data[report].state,
                                        url: response.data[report].url,
                                    },
                                ]);
                            } else {
                                this.addErrors([
                                    this.datasetGroupUuid,
                                    {
                                        type: response.data[report].state,
                                        image: response.data[report].state_meta
                                            ? response.data[report].state_meta
                                                  .current
                                            : null,
                                        total: response.data[report].state_meta
                                            ? response.data[report].state_meta
                                                  .total
                                            : null,
                                    },
                                ]);
                            }
                        }
                    }

                    this.addReports([this.datasetGroupUuid, response.data]);

                    if (
                        this.datasetGroupUuid in this.errors &&
                        this.errors[this.datasetGroupUuid] !== null &&
                        this.errors[this.datasetGroupUuid].type === 'error'
                    ) {
                        this.addErrors([this.datasetGroupUuid, null]);
                    }

                    this.loading = false;
                    this.pollCount++;
                    this.errorCount = 0;
                })
                .catch(error => {
                    if (error.response.status === 403) {
                        this.addErrors([
                            this.datasetGroupUuid,
                            { type: 'forbidden' },
                        ]);
                        throw error;
                    } else {
                        this.errorCount++;
                    }

                    if (this.errorCount > 5) {
                        this.addErrors([
                            this.datasetGroupUuid,
                            { type: 'error' },
                        ]);
                        throw error;
                    }
                });
        },
        generateReport() {
            trackReportAndExportEvents.reportGenerated(this.view);
            if (
                this.view_options[this.view.component].type !==
                    'inspection_view' &&
                this.view_options[this.view.component].type !==
                    'thermal_inspection_view'
            ) {
                SmartViewEventBus.$emit('cesiumReport', this.view.id);
                this.setIsReportMode(true);
                this.$emit('close-dialog');
            } else {
                axios
                    .post(`api/view/inspection/report/generate/`, {
                        view: this.view.viewUUID,
                        site: this.site_uuid,
                    })
                    .then(() => {
                        this.getReports();
                    })
                    .catch(error => {
                        this.addErrors([
                            this.datasetGroupUuid,
                            { type: 'error' },
                        ]);
                        throw error;
                    });
            }
        },
    },
};
</script>
