import Vue from 'vue';

export default {
    namespaced: true,
    state: {
        datasetGroups: {},
        errors: {},
    },
    mutations: {
        addReports(state, payload) {
            if (payload[0] && payload[1]) {
                Vue.set(state.datasetGroups, ...payload);
            }
        },
        addErrors(state, payload) {
            if (payload[0]) {
                Vue.set(state.errors, ...payload);
            }
        },
    },
};
