import { mapGetters, mapMutations } from 'vuex';

const color = 'blue-grey lighten-2';

export default {
    data() {
        return {
            view_options: {
                inspection_view: {
                    // the name of the view from the database
                    text: 'Inspection', // the name of the view displayed to users
                    value: `inspection`,
                    color, // the color for the date and views menus
                    icon: '$vuetify.icons.inspectionView', // the icon for the time and views menus
                    type: 'inspection_view', // the name of the view from the database again
                    downloadDescription: 'The original RGB images.',
                    disabled: true
                },
                ortho_view: {
                    text: '2D Map',
                    value: '2d',
                    color,
                    icon: '$vuetify.icons.orthoView',
                    type: 'ortho_view',
                    downloadDescription: 'A GeoTIFF of your 2D map.',
                    disabled: false
                },
                cesium_tiles_view: {
                    text: '3D Model',
                    value: '3d',
                    color,
                    icon: '$vuetify.icons.cesiumTilesView',
                    type: 'cesium_tiles_view',
                    downloadDescription: 'Your 3D model as a .obj.',
                    disabled: false
                },
                bim_view: {
                    text: 'BIM Model',
                    value: 'bim',
                    color,
                    icon: '$vuetify.icons.bimView',
                    type: 'bim_view',
                    downloadDescription: 'Your original ifc/dtm files.',
                    disabled: true
                },
                point_cloud_view: {
                    text: 'Point Cloud',
                    value: 'point_cloud',
                    color,
                    icon: '$vuetify.icons.pointCloudView',
                    type: 'point_cloud_view',
                    downloadDescription: 'The las/laz files of your asset.',
                    disabled: false
                },
                dtm_view: {
                    text: 'Terrain',
                    value: 'terrain_dtm',
                    color,
                    icon: '$vuetify.icons.terrainView',
                    type: 'dtm_view',
                    hiddenFromUploadPage: true,
                    downloadDescription: 'Terrain data in a .tiff format.',
                    disabled: false
                },
                terrain_view: {
                    text: 'Terrain',
                    value: 'terrain',
                    color,
                    icon: '$vuetify.icons.terrainView',
                    type: 'terrain_view',
                    secondary: true,
                    hiddenFromUploadPage: true,
                    downloadDescription: 'Terrain data in a .tiff format.',
                    disabled: false
                },
                thermal_inspection_view: {
                    text: 'Thermal Inspection',
                    value: 'thermal_inspection',
                    color,
                    icon: '$vuetify.icons.thermometer', // the icon for the time and views menus
                    type: 'thermal_inspection_view',
                    hiddenFromUploadPage: true,
                    downloadDescription: 'The original thermal images.',
                    disabled: false
                }
            }
        };
    },
    computed: {
        ...mapGetters('smartview', ['getIdCounter'])
    },
    methods: {
        ...mapMutations('smartview', ['incrementIdCounter']),
        createNewView(uuid, component = null) {
            return {
                component: component || 'Loading',
                datasetPackage: null,
                viewUUID: uuid,
                style: null,
                class: null,
                id: this.getId(),
                urlParam: null,
                state: 'Loading',
                stateProps: {},
                enableReports: !(
                    component === 'inspection_view' ||
                    component === 'thermal_inspection_view'
                )
            };
        },
        getId() {
            const id = this.getIdCounter;
            this.incrementIdCounter();
            return id;
        }
    }
};
