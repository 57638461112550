function sendEvent(eventName, eventMetaData) {
    if (
        !(
            location.host.includes('127.0.0.1') ||
            location.host.includes('localhost') ||
            location.host.includes('192.168.')
        )
    ) {
        window.Intercom('trackEvent', eventName, eventMetaData);
    }
}

function snakeCaseToDisplay(string) {
    return string
        .split('_')
        .join(' ')
        .toLowerCase()
        .split(' ')
        .map(s => s.charAt(0).toUpperCase() + s.slice(1))
        .join(' ');
}

export const trackTeamEvents = {
    addedUserToTeam: (userAdded, teamName) => {
        sendEvent('added-user-to-team', {
            user_added: userAdded,
            team_name: teamName
        });
    },
    removedUserFromTeam: (userAdded, teamName) => {
        sendEvent('removed-user-from-team', {
            user_removed: userAdded,
            team_name: teamName
        });
    },
    changedUserRole: (userChanged, userRole, teamName) => {
        sendEvent('changed-user-role', {
            user_changed: userChanged,
            user_role: userRole,
            team_name: teamName
        });
    },
    editedTeam: teamName => {
        sendEvent('edited-team', { team_name: teamName });
    },
    createdTeam: teamName => {
        sendEvent('created-team', { team_name: teamName });
    },
    deletedTeam: teamName => {
        sendEvent('deleted-team', { team_name: teamName });
    },
    leftTeam: teamName => {
        sendEvent('left-team', { team_name: teamName });
    }
};

export const trackProfileEvents = {
    editedProfile: () => {
        sendEvent('edited-profile');
    },
    changedPassword: () => {
        sendEvent('changed-password');
    },
    requestedPasswordReset: () => {
        sendEvent('requested-password-reset');
    },
    completedPasswordReset: () => {
        sendEvent('completed-password-reset');
    },
    changedNotificationSettings: (
        datasetNotifications,
        teamAndBillingNotifications
    ) => {
        datasetNotifications = datasetNotifications ? 'On' : 'Off';
        teamAndBillingNotifications = teamAndBillingNotifications
            ? 'On'
            : 'Off';

        sendEvent('changed-notification-settings', {
            dataset_notifications: datasetNotifications,
            team_and_billing_notifications: teamAndBillingNotifications
        });
    }
};

// converts a view object to a user friendly display name
function viewObjectToDisplay(viewObject) {
    const type = snakeCaseToDisplay(viewObject['component']);

    return `${viewObject['datasetPackage']['name']} ${viewObject['datasetPackage']['date']} ${type}`;
}

// converts an annotation object to a user friendly display name
function annotationObjectToDisplay(geoJson) {
    return JSON.parse(geoJson['annotation'])['features'][0]['geometry']['type'];
}

export const trackInspectionEvents = {
    annotationAdded: (viewObject, annotationObject) => {
        sendEvent('inspection-annotation-added', {
            view: viewObjectToDisplay(viewObject),
            annotation_type: annotationObjectToDisplay(annotationObject)
        });
    },
    annotationDeleted: (viewObject, annotationObject) => {
        sendEvent('inspection-annotation-deleted', {
            view: viewObjectToDisplay(viewObject),
            annotation_type: annotationObject.features[0].geometry.type
        });
    },
    annotationEdit: (viewObject, annotationObject) => {
        sendEvent('inspection-annotation-edited', {
            view: viewObjectToDisplay(viewObject),
            annotation_type: annotationObjectToDisplay(annotationObject)
        });
    }
};

export const trackCesiumEvents = {
    annotationAdded: (viewObject, annotationType) => {
        sendEvent('added-cesium-annotation', {
            view: viewObjectToDisplay(viewObject),
            annotation_type: annotationType
        });
    },
    annotationDeleted: (viewObject, annotationType) => {
        sendEvent('deleted-cesium-annotation', {
            view: viewObjectToDisplay(viewObject),
            annotation_type: annotationType
        });
    },
    allAnnotationsDeleted: viewObject => {
        sendEvent('deleted-all-cesium-annotations', {
            view: viewObjectToDisplay(viewObject)
        });
    },
    annotationEdit: (viewObject, annotationType) => {
        sendEvent('edited-cesium-annotation', {
            view: viewObjectToDisplay(viewObject),
            annotation_type: annotationType
        });
    },
    backToSourceUsed: viewObject => {
        sendEvent('used-back-to-source', {
            view: viewObjectToDisplay(viewObject)
        });
    },
    cesiumImport: (viewObject, fileType) => {
        sendEvent('used-cesium-file-import', {
            view: viewObjectToDisplay(viewObject),
            file_type: fileType
        });
    }
};

export const trackUploadEvents = {
    newLocation: location => {
        sendEvent('created-new-location', { location: location['name'] });
    },
    newDatasetGroup: asset => {
        sendEvent('created-new-dataset', {
            asset: asset['name']
        });
    },
    uploadStart: (site, dataset) => {
        sendEvent('started-upload', {
            site_name: site['name'],
            dataset_name: dataset['name']
        });
    }
};

export const trackReportAndExportEvents = {
    reportGenerated: viewObject => {
        sendEvent('generated-report', {
            view: viewObjectToDisplay(viewObject)
        });
    },
    exportDownloaded: (viewObject, fileType) => {
        sendEvent('downloaded-export', {
            view: viewObjectToDisplay(viewObject),
            file_type: fileType
        });
    }
};

function dashboardViewObjectToDisplay(viewObject) {
    const type = snakeCaseToDisplay(viewObject['viewType']);
    return `${viewObject['assetName']} ${viewObject['datasetDate']} ${type}`;
}

export const trackDashboardEvents = {
    syncViewUsed: views => {
        let viewsDisplay = [];
        views.forEach(v => {
            viewsDisplay.push(viewObjectToDisplay(v));
        });
        sendEvent('used-sync-views', { views: viewsDisplay.join(', ') });
    },
    favouriteAdded: viewObject => {
        sendEvent('added-favourite', {
            view: viewObjectToDisplay(viewObject)
        });
    },
    favouriteAddedDashboard: viewObject => {
        sendEvent('add-favourite', {
            view: dashboardViewObjectToDisplay(viewObject)
        });
    },
    favouriteRemovedDashboard: viewObject => {
        sendEvent('removed-favourite', {
            view: dashboardViewObjectToDisplay(viewObject)
        });
    },
    favouriteRemoved: viewObject => {
        sendEvent('removed-favourite', {
            view: viewObjectToDisplay(viewObject)
        });
    }
};

// TODO Track billing account events when billing accounts are reimplemented
export const billingAccountEvents = {
    createdBillingAccount: (company_name, contact_name) => {
        sendEvent('created-billing-account', {
            company_name: company_name,
            contact_name: contact_name
        });
    },
    editedBillingAccount: (company_name, contact_name) => {
        sendEvent('edited-billing-account', {
            company_name: company_name,
            contact_name: contact_name
        });
    },
    addedBillingAccountAdministrator: (
        company_name,
        contact_name,
        administrator_added
    ) => {
        sendEvent('added-billing-account-administrator', {
            company_name: company_name,
            contact_name: contact_name,
            administrator_added: administrator_added
        });
    },
    removedBillingAccountAdministrator: (
        company_name,
        contact_name,
        administrator_removed
    ) => {
        sendEvent('removed-billing-account-administrator', {
            company_name: company_name,
            contact_name: contact_name,
            administrator_removed: administrator_removed
        });
    }
};
