<template>
    <v-alert :color="color" style="color: white;">
        <template v-slot:prepend>
            <v-icon
                size="30"
                color="white"
                v-text="'$vuetify.icons.info'"
            ></v-icon>
        </template>
        <v-row align="center" no-gutters>
            <v-col class="grow">
                <b>Generating Report:</b>
                <span>{{ message }}</span>
            </v-col>
            <v-col class="shrink">
                <v-progress-circular
                    v-if="current"
                    size="23"
                    class="ml-4 my-4"
                    :value="(current / total) * 100"
                />
                <v-progress-circular
                    v-else
                    size="23"
                    class="ml-4 my-4"
                    indeterminate
                />
            </v-col>
        </v-row>
    </v-alert>
</template>

<script>
export default {
    name: 'ReportAlert',
    props: {
        color: {
            type: String,
            default: '',
        },
        message: {
            type: String,
            default: '',
        },
        current: {
            type: Number,
            default: 0,
        },
        total: {
            type: Number,
            default: 100,
        },
    },
};
</script>

<style scoped></style>
