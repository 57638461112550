import { findIndex } from 'lodash-es';
import httpClient from 'js/httpClient';

export default {
    namespaced: true,
    state: {
        userSitesArray: [],
        userSitesLoadStatus: false,
        pagination: {},
    },
    mutations: {
        setUserSitesLoadStatus(state, status) {
            state.userSitesLoadStatus = status;
        },
        setPagination(state, pagination) {
            state.pagination = pagination;
        },
        setUserSitesArray(state, newArray) {
            newArray = newArray.filter(site => {
                site.dataset_groups = site.dataset_groups.filter(
                    datasetGroup => {
                        return datasetGroup.canEditDatasetGroup !== false;
                    }
                );
                return site.userCanEditSite !== false;
            });
            state.userSitesArray = newArray;
        },
        editSite(state, newSite) {
            // find index from uuid
            const index = findIndex(state.userSitesArray, {
                uuid: newSite.uuid,
            });
            // replace info
            state.userSitesArray[index].name = newSite.name;
            state.userSitesArray[index].description = newSite.description;
            state.userSitesArray[index].address = newSite.address;
        },
        editAsset(state, newAsset) {
            // eslint-disable-next-line guard-for-in,no-restricted-syntax
            for (const site in state.userSitesArray) {
                // eslint-disable-next-line no-restricted-syntax
                for (const datasetGroup in state.userSitesArray[site]
                    .dataset_groups) {
                    if (
                        state.userSitesArray[site].dataset_groups[datasetGroup]
                            .uuid === newAsset.uuid
                    ) {
                        state.userSitesArray[site].dataset_groups[
                            datasetGroup
                        ].name = newAsset.name;
                        state.userSitesArray[site].dataset_groups[
                            datasetGroup
                        ].description = newAsset.description;
                        // Early return if the asset has been updated
                        return;
                    }
                }
            }
        },
        editDataset(state, payload) {
            const [
                siteUuid,
                datasetGroupUuid,
                datasetUuid,
                viewType,
                viewUuid,
            ] = payload;

            const siteIndex = findIndex(state.userSitesArray, {
                uuid: siteUuid,
            });

            const datasetGroupIndex = findIndex(
                state.userSitesArray[siteIndex].dataset_groups,
                {
                    uuid: datasetGroupUuid,
                }
            );

            const datasetIndex = findIndex(
                state.userSitesArray[siteIndex].dataset_groups[
                    datasetGroupIndex
                ].datasets,
                {
                    uuid: datasetUuid,
                }
            );
            state.userSitesArray[siteIndex].dataset_groups[
                datasetGroupIndex
            ].datasets[datasetIndex][viewType] = viewUuid;
            state.userSitesArray[siteIndex].dataset_groups[
                datasetGroupIndex
            ].datasets[datasetIndex][`${viewType}_published`] = false;
        },
    },
    actions: {
        /**
         *
         * @param {*} context
         * @param {*} {uuid, name, page, user_can_edit_site} | None
         * @author: Williams Francis <w.francis@nationaldrones.com> | <olawalewilliams9438@gmail.com>
         */
        loadUserSitesArray(
            context,
            {
                uuid = null,
                name = null,
                page = 1,
                user_can_edit_site = null,
            } = {}
        ) {
            // eslint-disable-next-line no-undef
            httpClient
                .get(
                    name === null && uuid === null
                        ? `/api/v1/sites/?page=${page}&user_can_edit_site=${user_can_edit_site}`
                        : uuid === null
                        ? `/api/v1/sites/?name=${name}&user_can_edit_site=${user_can_edit_site}`
                        : `/api/v1/sites/?uuid=${uuid}&user_can_edit_site=${user_can_edit_site}`
                )
                .then(response => {
                    let total_pages = response.data['total_pages'];
                    let totalRecordCount = response.data['count'];
                    let next = response.data['next'];
                    let previous = response.data['previous'];
                    let current_page = page;
                    let pagination = {
                        totalRecordCount: totalRecordCount,
                        totalPageCount: total_pages,
                        next: next,
                        previous: previous,
                        current_page: current_page,
                    };
                    let userSitesArray = response.data['results'];
                    context.commit('setUserSitesArray', userSitesArray);
                    context.commit('setUserSitesLoadStatus', true);
                    context.commit('setPagination', pagination);
                })
                .catch(error => {
                    throw error;
                });
        },
    },
};
