import { mapMutations } from 'vuex';

export default {
    methods: {
        ...mapMutations('smartview', [
            'replaceViews',
            'replaceFirstView',
            'addToEndViews',
            'replaceLastView',
        ]),

        changeViewMain(view) {
            if (view) this.replaceViews(this.createNewView(view.uuid));
        },
        changeViewLeft(view) {
            if (view) {
                if (this.viewsOnPage.length === 1) {
                    this.addToStartViews(this.createNewView(view.uuid));
                } else {
                    this.replaceFirstView(this.createNewView(view.uuid));
                }
            }
        },
        changeViewRight(view) {
            if (view) {
                if (this.viewsOnPage.length === 1) {
                    this.addToEndViews(this.createNewView(view.uuid));
                } else {
                    this.replaceLastView(this.createNewView(view.uuid));
                }
            }
        },
        /**
         * Returns a view object to put into the 'views' array in the smartview vuex store
         *
         * @param {string} uuid viewUuid for the view
         * @param {string|null} component to load for the view
         * @returns {object} view object
         * @author Conor Brosnan <c.brosnan@nationaldrones.com>
         */
        createNewView(uuid, component = null) {
            return {
                component: component || 'Loading',
                datasetPackage: null,
                viewUUID: uuid,
                style: null,
                class: null,
                id: this.getId(),
                urlParam: null,
                state: 'Loading',
                isFavourite: false,
                stateProps: {},
                enableReports: !(
                    component === 'inspection_view' ||
                    component === 'thermal_inspection_view'
                ),
                siteSelectorValue: this.sites_select,
            };
        },
    },
};
