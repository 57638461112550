import axios from 'axios';
import httpClient from 'js/httpClient';
import { findIndex } from 'lodash';

const { CancelToken } = axios;
let cancel;

/**
 * Used for resetting the QA Viewer
 *
 * @type {function(): {view: [], display: boolean, viewObject: null, viewComponent: null}}
 * @author Conor Brosnan <c.brosnan@nationaldrones.com>
 */
const initQaView = () => {
    return {
        display: false,
        viewObject: null,
        viewComponent: null,
        view: [],
    };
};

export default {
    namespaced: true,
    state: {
        viewsToProcess: [],
        claimedViews: [],
        qaQueue: [],
        viewsLoadStatusPending: null,
        viewsLoadStatusSuccess: null,
        viewsLoadStatusError: null,
        qaView: initQaView(),
    },
    mutations: {
        SET_VIEWS_TO_PROCESS(state, payload) {
            state.viewsToProcess = payload;
        },
        SET_CLAIMED_VIEWS(state, payload) {
            state.claimedViews = payload;
        },
        SET_QA_QUEUE(state, payload) {
            state.qaQueue = payload;
        },
        SET_VIEWS_LOAD_STATUS_PENDING(state, payload) {
            state.viewsLoadStatusPending = payload;
        },
        SET_VIEWS_LOAD_STATUS_SUCCESS(state, payload) {
            state.viewsLoadStatusSuccess = payload;
        },
        SET_VIEWS_LOAD_STATUS_FAIL(state, payload) {
            state.viewsLoadStatusError = payload;
        },
        SET_VIEW_CLAIM_BUTTON_STATUS(state, payload) {
            state.viewsToProcess[payload[0]].claim = payload[1];
        },
        SET_VIEW_REMOVE_CLAIM_BUTTON_STATUS(state, payload) {
            state.claimedViews[payload[0]].removeClaim = payload[1];
        },
        SET_VIEW_MOVE_TO_QA_BUTTON_STATUS(state, payload) {
            state.claimedViews[payload[0]].moveToQa = payload[1];
        },
        SET_VIEW_ACCEPT_BUTTON_STATUS(state, payload) {
            state.qaQueue[payload[0]].accept = payload[1];
        },
        SET_VIEW_REJECT_BUTTON_STATUS(state, payload) {
            state.qaQueue[payload[0]].reject = payload[1];
        },
        OPEN_QA_VIEW(state, payload) {
            state.qaView.view = payload[0];

            const view = payload[1];
            state.qaView.viewComponent = view.viewType;
            state.qaView.viewObject = view;
            state.qaView.display = true;
        },
        CLOSE_QA_VIEW(state) {
            state.qaView = initQaView();
        },
    },
    actions: {
        getViews({ commit }) {
            // eslint-disable-next-line no-unused-expressions
            cancel && cancel();
            commit('SET_VIEWS_LOAD_STATUS_PENDING', true);

            httpClient
                .get('/api/process/get_views/', {
                    cancelToken: new CancelToken(c => {
                        cancel = c;
                    }),
                })
                .then(response => {
                    commit(
                        'SET_VIEWS_TO_PROCESS',
                        response.data.filter(view => view.claim === true)
                    );

                    commit(
                        'SET_CLAIMED_VIEWS',
                        response.data.filter(view => view.upload === true)
                    );

                    commit(
                        'SET_QA_QUEUE',
                        response.data.filter(view => view.accept === true)
                    );
                    commit('SET_VIEWS_LOAD_STATUS_PENDING', false);
                    commit('SET_VIEWS_LOAD_STATUS_SUCCESS', true);
                })
                .catch(error => {
                    // Not an error if the request was canceled
                    if (!axios.isCancel(error)) {
                        commit('SET_VIEWS_LOAD_STATUS_PENDING', false);
                        commit('SET_VIEWS_LOAD_STATUS_FAIL', true);
                        throw error;
                    }
                });
        },
        // VIEWS TO PROCESS PAGE BUTTONS
        setViewClaimButtonStatus({ commit, state }, { view, status }) {
            const index = findIndex(state.viewsToProcess, {
                viewUuid: view.viewUuid,
            });
            commit('SET_VIEW_CLAIM_BUTTON_STATUS', [index, status]);
        },
        // CLAIMED PAGE BUTTONS
        setViewRemoveClaimButtonStatus({ commit, state }, { view, status }) {
            const index = findIndex(state.claimedViews, {
                viewUuid: view.viewUuid,
            });
            commit('SET_VIEW_REMOVE_CLAIM_BUTTON_STATUS', [index, status]);
        },
        setViewMoveToQaButtonStatus({ commit, state }, { view, status }) {
            const index = findIndex(state.claimedViews, {
                viewUuid: view.viewUuid,
            });
            commit('SET_VIEW_MOVE_TO_QA_BUTTON_STATUS', [index, status]);
        },
        // QA PAGE BUTTONS
        setViewAcceptButtonStatus({ commit, state }, { view, status }) {
            const index = findIndex(state.qaQueue, {
                viewUuid: view.viewUuid,
            });
            commit('SET_VIEW_ACCEPT_BUTTON_STATUS', [index, status]);
        },
        setViewRejectButtonStatus({ commit, state }, { view, status }) {
            const index = findIndex(state.qaQueue, {
                viewUuid: view.viewUuid,
            });
            commit('SET_VIEW_REJECT_BUTTON_STATUS', [index, status]);
        },
        openQaView({ commit }, { smartviewViewObject, view }) {
            commit('OPEN_QA_VIEW', [smartviewViewObject, view]);
        },
        closeQaView({ commit }) {
            commit('CLOSE_QA_VIEW');
        },
    },
};
