import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VHover } from 'vuetify/lib/components/VHover';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('smart-view-dialogue-base',{attrs:{"close":function () {
            this$1.$emit('close-dialog');
        }},scopedSlots:_vm._u([{key:"heading",fn:function(){return [_vm._v("\n        Downloads for "+_vm._s(_vm.datasetGroupName)+"\n    ")]},proxy:true},{key:"content",fn:function(){return [_c(VContainer,[(_vm.errors.error)?_c(VAlert,{attrs:{"type":"error"}},[_vm._v("\n                There was an error updating the list of downloads.\n            ")]):_vm._e(),_vm._v(" "),(_vm.errors.forbidden)?_c(VAlert,{attrs:{"type":"warning"}},[_vm._v("\n                You do not have access to the downloads for this dataset.\n            ")]):_vm._e(),_vm._v(" "),(_vm.errors.processRequestError)?_c(VAlert,{attrs:{"type":"warning"}},[_vm._v("\n                There was an error sending your download request, please try\n                again. If the error persists please contact support.\n            ")]):_vm._e(),_vm._v(" "),(_vm.waiting.length > 0)?_c(VAlert,{attrs:{"type":"info"}},[_vm._v("\n                Your downloads are processing, keep this dialog open to\n                download them once they are ready. Or you can come back\n                later and download.\n            ")]):_vm._e()],1),_vm._v(" "),(!_vm.datasetDownloads.length > 0)?_c(VLayout,{staticClass:"justify-center align-center ma-5"},[_c(VProgressCircular,{attrs:{"indeterminate":"","color":"primary"}})],1):_vm._e(),_vm._v(" "),_c(VLayout,{staticClass:"justify-center"},[(_vm.times.length > 0 && _vm.datasetDownloads.length > 0)?_c(VSelect,{staticStyle:{"max-width":"350px"},attrs:{"items":_vm.times,"no-data-text":"No times available.","item-value":'uuid',"label":"Date","item-text":'dateTime'},model:{value:(_vm.selectedTime),callback:function ($$v) {_vm.selectedTime=$$v},expression:"selectedTime"}}):_vm._e()],1),_vm._v(" "),(_vm.datasetDownloads.length > 0)?[_c(VLayout,{attrs:{"wrap":""}},_vm._l((_vm.datasetDownloads),function(download,i){return _c(VFlex,{key:i,attrs:{"pa-2":"","xs12":"","sm6":"","md4":""}},[_c(VHover,{scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var hover = ref.hover;
return [_c(VCard,{staticClass:"justify-center",attrs:{"elevation":hover ? 12 : 2}},[_c(VCardTitle,[_vm._v("\n                                "+_vm._s(_vm.view_options[download.type].text)+"\n                            ")]),_vm._v(" "),_c(VCardText,[(download.state !== 'error')?[_vm._v(_vm._s(_vm.view_options[download.type]
                                            .downloadDescription)+"\n                                ")]:[_vm._v("\n                                    Error with the download.\n                                ")]],2),_vm._v(" "),_c(VCardActions,[_c(VBtn,{staticStyle:{"color":"white"},attrs:{"color":_vm.view_options[download.type].color,"loading":download.state === 'requested' ||
                                            download.state === 'processing'},on:{"click":function () {
                                            download.state = 'requested';
                                            _vm.downloadButtonClick(download);
                                        }}},[_vm._v("\n                                    Download\n                                ")])],1)],1)]}}],null,true)})],1)}),1)]:_vm._e()]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }