import { find, findIndex } from 'lodash';
import Vue from 'vue';

const _ = { find, findIndex };

export default {
    namespaced: true,
    state: {
        views: [],
        selectedView: 0,
        idCounter: 0,
        isReportMode: false,
        debuggingBackToSource: false,
        cameraData: {
            cameraPos: {
                lat: null,
                lon: null,
                alt: null
            },
            clickPos: {
                lat: null,
                lon: null,
                alt: null
            },
            orientation: {
                pitch: null,
                roll: null,
                yaw: null
            },
            sourceImageUuid: null
        },
        syncViews: false,
        selectedViewIsFavorite: false
    },
    getters: {
        selectedViewDetails: state => {
            return state.views[state.selectedView];
        },
        selectedViewIsFavourite: state => {
            if (state.views[state.selectedView] !== undefined) {
                return state.views[state.selectedView].isFavourite;
            }
            return false;
        },
        viewsOnPage: state => {
            return state.views;
        },
        selectedView: state => {
            return state.selectedView;
        },
        getIdCounter: state => {
            return state.idCounter;
        },
        getIsReportMode: state => {
            return state.isReportMode;
        },
        cameraData: state => {
            return state.cameraData;
        },
        syncViews: state => {
            return state.syncViews;
        },
        debuggingBackToSource: state => {
            return state.debuggingBackToSource;
        }
    },
    mutations: {
        replaceFirstView(state, view) {
            view.id = state.views[0].id;
            Vue.set(state.views, 0, view);
        },
        replaceLastView(state, view) {
            view.id = state.views[state.views.length - 1].id;
            Vue.set(state.views, state.views.length - 1, view);
        },
        addToStartViews(state, view) {
            state.views.unshift(view);
        },
        setDebuggingBackToSource(state, bool) {
            state.debuggingBackToSource = bool;
        },
        addToEndViews(state, view) {
            state.views.push(view);
        },
        replaceViews(state, view) {
            if (view.component) {
                // if component is provided, try to find a view already using the component so that it doesnt have to reinitialise
                const existingView = _.find(state.views, {
                    component: view.component
                });
                if (existingView) {
                    view.id = existingView.id;
                }
            }
            state.views = [view];
            [state.selectedView] = state.views;
        },
        setViewComponent(state, payload) {
            // eslint-disable-next-line prefer-destructuring
            state.views[payload[0]].component = payload[1];
            state.views[payload[0]].enableReports = !(
                payload[1] === 'inspection_view' ||
                payload[1] === 'thermal_inspection_view'
            );
        },
        setViewIsFavourite(state, payload) {
            // eslint-disable-next-line prefer-destructuring
            state.views[payload[0]].isFavourite = payload[1];
        },
        setViewDatasetPackage(state, payload) {
            // eslint-disable-next-line prefer-destructuring
            state.views[payload[0]].datasetPackage = payload[1];
        },
        setViewStyle(state, payload) {
            // eslint-disable-next-line prefer-destructuring
            state.views[payload[0]].style = payload[1];
        },
        setSelectedView(state, viewIndex) {
            state.selectedView = viewIndex;
        },
        setSyncedViews(state, bool) {
            state.syncViews = bool;
        },
        setEnableReports(state, bool) {
            Vue.set(state.views[state.selectedView], 'enableReports', bool);
        },
        setEnableReportsView(state, payload) {
            const index = _.findIndex(state.views, { id: payload[0] });
            Vue.set(state.views[index], 'enableReports', payload[1]);
        },
        incrementIdCounter(state) {
            state.idCounter++;
        },
        removeView(state, view) {
            state.views.splice(view, 1);
            if (state.selectedView > state.views.length) {
                state.selectedView = 0;
            }
        },
        removeAllViews(state) {
            state.views = [];
        },
        setUrlParam(state, payload) {
            const index = _.findIndex(state.views, { id: payload[0] });
            // eslint-disable-next-line prefer-destructuring
            state.views[index].urlParam = payload[1];
        },
        setState(state, payload) {
            const index = _.findIndex(state.views, { id: payload[0] });
            // eslint-disable-next-line prefer-destructuring
            state.views[index].state = payload[1];
        },
        setStateProps(state, payload) {
            const index = _.findIndex(state.views, { id: payload[0] });
            // eslint-disable-next-line prefer-destructuring
            state.views[index].stateProps = payload[1];
        },
        reverseViewsOrder(state) {
            state.views.reverse();
        },
        setIsReportMode(state, bool = null) {
            if (bool === null) {
                state.isReportMode = !state.isReportMode;
            } else {
                state.isReportMode = bool;
            }
        },
        updateCamera(state, payload) {
            // this function is called from any view to update the central camera data
            // payload should have keys camera_pos and orientation. click_pos and uuid keys are optional
            state.cameraData.cameraPos = {
                lat: payload.camera_pos.lat,
                lon: payload.camera_pos.lon,
                alt: payload.camera_pos.alt
            };
            if ('click_pos' in payload) {
                state.cameraData.clickPos = {
                    lat: payload.click_pos.lat,
                    lon: payload.click_pos.lon,
                    alt: payload.click_pos.alt
                };
            }

            state.cameraData.orientation = {
                pitch: payload.orientation.pitch,
                roll: payload.orientation.roll,
                yaw: payload.orientation.yaw
            };
            if ('uuid' in payload) {
                state.cameraData.sourceImageUuid = payload.uuid;
            } else {
                state.cameraData.sourceImageUuid = null;
            }
        },
        updateClickPos(state, payload) {
            state.cameraData.clickPos = {
                lat: payload.pos.lat,
                lon: payload.pos.lon,
                alt: payload.pos.alt
            };
        }
    }
};
