import { findIndex } from 'lodash-es';

export default {
    namespaced: true,
    state: {
        usersTeams: [],
    },
    mutations: {
        setUsersTeams(state, newArray) {
            state.usersTeams = newArray;
        },
        createTeam(state, newTeam) {
            state.usersTeams.push(newTeam);
        },
        editTeam(state, newTeam) {
            let index = findIndex(state.usersTeams, { id: newTeam.uuid });
            // replace info
            state.usersTeams[index].name = newTeam.name;
            state.usersTeams[index].description = newTeam.description;
            state.usersTeams[index].logoUrl = newTeam.logoUrl;
        },
        deleteTeam(state, teamId) {
            let index = findIndex(state.usersTeams, { id: teamId });
            state.usersTeams.splice(index, 1);
        },
    },
    actions: {
        loadUserTeams(context) {
            // eslint-disable-next-line no-undef
            context.commit('setUsersTeams', teams);
        },
    },
};
