<template>
    <smart-view-dialogue-base
        :close="
            () => {
                this.$emit('close-dialog');
            }
        "
    >
        <template v-slot:heading>
            Export Annotations
        </template>
        <template v-slot:content>
            <v-card-text>
                {{ formatsAvailable }}
            </v-card-text>

            <v-card-actions class="justify-center pb-5">
                <v-btn
                    v-for="(format, i) in formats"
                    :key="i"
                    :loading="loading[format]"
                    color="primary"
                    @click="exportAnnotations(format)"
                >
                    {{ format }}
                </v-btn>
            </v-card-actions>
        </template>
    </smart-view-dialogue-base>
</template>

<script>
import SmartViewDialogueBase from 'components/View/SmartViewDialogueBase.vue';
import { trackReportAndExportEvents } from 'js/eventsTracking';
import { SmartViewEventBus } from 'store/store';
import { mapGetters } from 'vuex';

export default {
    name: 'Exports',
    components: { SmartViewDialogueBase },
    props: {
        view: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            loading: {
                CSV: false,
                KML: false,
                Text: false,
            },
        };
    },
    computed: {
        ...mapGetters('smartview', ['viewsOnPage', 'getIsReportMode']),
        formats() {
            const cesiumFormats = this.isCesium ? ['KML', 'Text'] : [];
            return ['CSV', ...cesiumFormats];
        },
        isCesium() {
            return (
                this.view.component !== 'inspection_view' &&
                this.view.component !== 'thermal_inspection_view'
            );
        },
        formatsAvailable() {
            return this.isCesium
                ? 'Available annotation export formats include KML, CSV and .txt files.'
                : 'Inspection annotations can be exported in CSV format.';
        },
    },
    mounted() {
        SmartViewEventBus.$on('closeExportDialog', () => {
            this.$emit('close-dialog');
            this.loading = {
                CSV: false,
                KML: false,
                Text: false,
            };
        });
    },
    methods: {
        exportAnnotations(format) {
            trackReportAndExportEvents.exportDownloaded(
                this.viewsOnPage[this.view.id],
                format
            );
            SmartViewEventBus.$emit(`exportAnnotations${format}`, this.view.id);
            this.loading[format] = true;
        },
    },
};
</script>
