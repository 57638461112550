<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
    <smart-view-dialogue-base
        :close="
            () => {
                this.$emit('close-dialog');
            }
        "
    >
        <template v-slot:heading>
            Downloads for {{ datasetGroupName }}
        </template>
        <template v-slot:content>
            <v-container>
                <v-alert v-if="errors.error" type="error">
                    There was an error updating the list of downloads.
                </v-alert>
                <v-alert v-if="errors.forbidden" type="warning">
                    You do not have access to the downloads for this dataset.
                </v-alert>
                <v-alert v-if="errors.processRequestError" type="warning">
                    There was an error sending your download request, please try
                    again. If the error persists please contact support.
                </v-alert>
                <v-alert v-if="waiting.length > 0" type="info">
                    Your downloads are processing, keep this dialog open to
                    download them once they are ready. Or you can come back
                    later and download.
                </v-alert>
            </v-container>

            <v-layout
                v-if="!datasetDownloads.length > 0"
                class="justify-center align-center ma-5"
            >
                <v-progress-circular
                    indeterminate
                    color="primary"
                ></v-progress-circular>
            </v-layout>

            <v-layout class="justify-center">
                <v-select
                    v-if="times.length > 0 && datasetDownloads.length > 0"
                    v-model="selectedTime"
                    :items="times"
                    no-data-text="No times available."
                    :item-value="'uuid'"
                    label="Date"
                    :item-text="'dateTime'"
                    style="max-width: 350px;"
                >
                </v-select>
            </v-layout>
            <template v-if="datasetDownloads.length > 0">
                <v-layout wrap>
                    <v-flex
                        v-for="(download, i) in datasetDownloads"
                        :key="i"
                        pa-2
                        xs12
                        sm6
                        md4
                    >
                        <v-hover v-slot:default="{ hover }">
                            <v-card
                                class="justify-center"
                                :elevation="hover ? 12 : 2"
                            >
                                <v-card-title>
                                    {{ view_options[download.type].text }}
                                </v-card-title>
                                <v-card-text>
                                    <template v-if="download.state !== 'error'"
                                        >{{
                                            view_options[download.type]
                                                .downloadDescription
                                        }}
                                    </template>
                                    <template v-else>
                                        Error with the download.
                                    </template>
                                </v-card-text>
                                <v-card-actions>
                                    <v-btn
                                        :color="
                                            view_options[download.type].color
                                        "
                                        :loading="
                                            download.state === 'requested' ||
                                                download.state === 'processing'
                                        "
                                        style="color: white"
                                        @click="
                                            () => {
                                                download.state = 'requested';
                                                downloadButtonClick(download);
                                            }
                                        "
                                    >
                                        Download
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-hover>
                    </v-flex>
                </v-layout>
            </template>
        </template>
    </smart-view-dialogue-base>
</template>

<script>
import axios from 'axios';
import SmartViewDialogueBase from 'components/View/SmartViewDialogueBase';
import viewsInfoMixin from 'js/mixins/viewsInfoMixin';
import { findIndex } from 'lodash';
import { mapMutations, mapState } from 'vuex';

const _ = {
    findIndex,
};

export default {
    name: 'Downloads',
    components: {
        SmartViewDialogueBase,
    },
    mixins: [viewsInfoMixin],
    props: {
        view: {
            type: Object,
            default: () => {
                return {
                    datasetPackage: {
                        dataset_name: 'Dataset Name',
                        dataset_group_uuid:
                            '11111111-1111-1111-1111-1111111111',
                    },
                };
            },
        },
    },
    data: () => {
        return {
            selectedTime: null,
            errors: {
                error: false,
                forbidden: false,
                processRequest: false,
            },
            waiting: [],
            pollInterval: null,
        };
    },
    computed: {
        ...mapState('downloads', ['datasetGroups', 'datasetTimes', 'errors']),
        datasetGroupUuid() {
            return this.view.datasetPackage.dataset_group_uuid;
        },
        datasetGroupName() {
            return this.view.datasetPackage.name;
        },
        times() {
            return typeof this.datasetTimes[this.datasetGroupUuid] ===
                'undefined'
                ? []
                : this.datasetTimes[this.datasetGroupUuid];
        },
        datasetGroupDownloads() {
            return this.datasetGroups[this.datasetGroupUuid];
        },
        datasetDownloads() {
            if (!this.selectedTime) return [];
            return this.datasetGroupDownloads[this.selectedTime];
        },
    },
    watch: {
        datasetGroupUuid: {
            immediate: true,
            handler() {
                this.selectedTime = null;
                this.getDownloads();
            },
        },
    },
    mounted() {
        this.getDownloads();
        this.pollInterval = setInterval(() => {
            this.getDownloads();
        }, 5000); // update every 5 seconds
    },
    beforeDestroy() {
        clearInterval(this.pollInterval);
    },
    methods: {
        ...mapMutations('downloads', ['addDownloads', 'addTimes', 'addErrors']),
        /**
         * Updates the downloads for the current dataset group.
         *
         * @author Conor Brosnan <c.brosnan@nationaldrones.com>
         */
        getDownloads() {
            axios
                .get(`/api/view/get_downloads/${this.datasetGroupUuid}/`)
                .then(response => response.data)
                .then(data => {
                    this.addTimes([this.datasetGroupUuid, data.datasets]);
                    this.addDownloads([this.datasetGroupUuid, data.downloads]);

                    this.setSelectedTime();
                    this.checkWaitingDownloads();

                    // If there was a process request error, leave that up until the user tries another process request
                    this.errors.error = false;
                    this.errors.forbidden = false;
                })
                .catch(error => {
                    if (error.response.status === 403) {
                        this.errors.forbidden = true;
                    } else {
                        this.errors.error = true;
                    }
                    throw error;
                });
        },
        /**
         * Checks whether any of the waiting downloads are ready or have errored
         * and updates the waiting array
         *
         * @author Conor Brosnan <c.brosnan@nationaldrones.com>
         */
        checkWaitingDownloads() {
            for (let i = 0; i < this.waiting.length; i++) {
                const index = _.findIndex(
                    this.datasetGroupDownloads[this.waiting[i].datasetUuid],
                    {
                        viewUuid: this.waiting[i].viewUuid,
                    }
                );

                const download = this.datasetDownloads[index];

                if (download.state === 'ready') {
                    this.triggerDownload(download);
                    this.waiting.splice(i);
                } else if (download.state === 'error') {
                    this.waiting.splice(i);
                }
            }
        },
        triggerDownload({ url, fileName }) {
            const link = document.createElement('a');
            link.download = fileName;
            link.href = url;
            link.click();
        },
        /**
         * If the dialog was just opened or the dataset group uuid changed,
         * the selected time will be null. Is set to the currently open view
         *
         * @author Conor Brosnan <c.brosnan@nationaldrones.com>
         */
        setSelectedTime() {
            if (this.selectedTime === null) {
                this.$nextTick(() => {
                    const index = _.findIndex(this.times, {
                        uuid: this.view.datasetPackage.uuid,
                    });
                    this.selectedTime = this.times[index].uuid;
                });
            }
        },
        /**
         * Adds a download to the waiting list so the download can be triggered
         * once it is ready.
         *
         * @param {string} viewUuid the download's view uuid
         * @param {string} datasetUuid the download's dataset uuid
         * @author Conor Brosnan <c.brosnan@nationaldrones.com>
         */
        addToWaiting(viewUuid, datasetUuid) {
            this.waiting.push({ viewUuid, datasetUuid });
        },
        sendProcessRequest({ url, viewUuid }) {
            this.errors.processRequestError = false;
            axios
                .post(url)
                .then(() => {
                    this.getDownloads();
                    this.errors.processRequestError = false;
                    this.addToWaiting(viewUuid, this.selectedTime);
                })
                .catch(error => {
                    this.errors.processRequestError = true;
                    throw error;
                });
        },
        /**
         * Handles the download button click on a download
         *
         * @param {object}download the download the button was clicked on
         * @author: Williams Francis <w.francis@nationaldrones.com> | <olawalewilliams9438@gmail.com>
         */
        downloadButtonClick(download) {
            // If the file is not ready on S3
            if (download.processRequest) {
                this.sendProcessRequest(download);
            } else {
                var s3_file_path = download.s3_file_path
                var file_name = download.fileName    
                axios.post(
                    'api/view/generate_pre_signed_download_s3_file_url/',
                    JSON.stringify({ s3_file_path: s3_file_path, file_name: file_name})
                ).then((response) => {
                    // Replace cloudfront url wuth signed generated file downoad_url
                    download.url = response.data.download_url
                    // console.log(response.data.download_url);
                    // console.log(download.url)
                    this.triggerDownload(download);
                })
                .catch(error => {
                    throw error;
                });
            }
        },
    },
};
</script>

<style scoped></style>
