import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';

var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('smart-view-dialogue-base',{attrs:{"close":function () {
            this$1.$emit('close-dialog');
        }},scopedSlots:_vm._u([{key:"heading",fn:function(){return [_vm._v("\n        Export Annotations\n    ")]},proxy:true},{key:"content",fn:function(){return [_c(VCardText,[_vm._v("\n            "+_vm._s(_vm.formatsAvailable)+"\n        ")]),_vm._v(" "),_c(VCardActions,{staticClass:"justify-center pb-5"},_vm._l((_vm.formats),function(format,i){return _c(VBtn,{key:i,attrs:{"loading":_vm.loading[format],"color":"primary"},on:{"click":function($event){return _vm.exportAnnotations(format)}}},[_vm._v("\n                "+_vm._s(format)+"\n            ")])}),1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }