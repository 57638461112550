<template>
    <v-card class="elevation-0 text-center pt-5">
        <v-row style="margin: 0;">
            <div class="full-width headline mb-3">
                <slot name="heading" />
            </div>
            <v-btn text icon class="dialog-close-btn" @click="close">
                <v-icon>$vuetify.icons.close</v-icon>
            </v-btn>
        </v-row>
        <span>
            <slot name="content" />
        </span>
    </v-card>
</template>

<script>
/**
 * Use this base component for any dialogues rendered in smartview
 */
export default {
    name: 'SmartViewDialogueBase',
    props: {
        close: {
            type: Function,
            default() {
                this.$emit('close-dialog');
            },
        },
    },
};
</script>
